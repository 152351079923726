.portfolio h1{
  color: #fff;
  font-family: 'Righteous','Open sans',helvetica,sans-serif;
  font-size: 52px;
  line-height: 52px;
  margin: 0;
  text-shadow: 0 2px 2px rgba(0,0,0,.1);
}
.portfolio h2{
  color: #fff;
  font-family: 'Righteous','Open sans',helvetica,sans-serif;
  font-size: 24px;
  margin-bottom: 32px;
}
.portfolio p{
  margin-bottom: 32px;
}
.header{
  background-color: #606ee5;
  text-align: center;
  padding: 100px 0;
}
.portfolio-intro p{
  color: #fff;
}
.portfolio-item{
  display: flex;
}
.portfolio-item .item:last-of-type{
  padding: 0;
}
.portfolio-item.alternate{
  flex-direction: row-reverse;
}
.portfolio-item.alternate .item:last-of-type{
  background-color: #606ee5;
}
.portfolio-item a{
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  width: 100%;
}
.portfolio-item a svg{
  padding-right: 10px;
}
.portfolio-banner{
  background-color: #efeff5;
  display: flex;
  justify-content: center;
  padding: 96px;
}
.portfolio-banner.alternate{
  background-color: #222227;
}
.portfolio-banner.alternate h2{
  color: #fff;
}
.portfolio-banner.alternate p{
  color: #fff;
}
.portfolio-banner article{
  max-width: 496px;
}
.portfolio-banner h2{
  color: #222227;
}
.portfolio-item:first-of-type .item:first-of-type{
  background-color: #222227;
}
.portfolio-item .item:first-of-type{
  background-color: #606ee5;
}
.portfolio-item .item{
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 50%;
}
.portfolio-item div{
  max-width: 500px;
}
.portfolio-item img{
  display: flex;
  width: 100%;
}
.portfolio-item h2{
  color: #fff;
  font-family: 'Righteous','Open sans',helvetica,sans-serif;
  font-size: 24px;
}
.portfolio-item p{
  color: #fff;
}
.portfolio-tech{
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 50px 20px;
}
.portfolio-tech ul{
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  padding: 0;
}
.portfolio-tech li{
  display: flex;
  height: 64px;
  list-style: none;
  margin-right: 10px;
  overflow: hidden;
  position: relative;
  width: 64px;
}
.portfolio-tech li:last-of-type{
  margin: 0;
}
.portfolio-tech li:hover div{
  background-color: #606ee5;
  opacity: 1;
  transform: translateY(0);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}
.portfolio-tech img{
  width: 100%;
}
.portfolio-tech div{
  align-items: center;
  border-radius: 5px;
  box-sizing: border-box;
  color: #131315;
  display: flex;
  flex-direction: row;
  font-family: 'Righteous','Open sans',helvetica,sans-serif;
  font-size: 12px;
  height: 64px;
  justify-content: center;
  line-height: 14px;
  opacity: 0;
  overflow: hidden;
  padding: 4px;
  position: absolute;
  text-align: center;
  transform: translateY(100%);
  width: 64px;
}
@media (max-width: 1150px) {
  .header{
    padding: 100px 20px;
  }
  .portfolio-item{
    flex-direction: column;
  }
  .portfolio-item.alternate{
    flex-direction: column;
  }
  .portfolio-item .item{
    padding: 50px 20px;
    width: 100%;
  }
  .portfolio-item:first-of-type .item:first-of-type{
    padding: 50px 20px;
  }
  .portfolio-banner{
    padding: 50px 20px;
  }
}